import { api, requestConfig } from '../utils/config';
import { IMessage } from '../Interfaces/IChat';

/**
 * Busca as mensagens de um chat específico.
 * @param chatId - ID do chat.
 * @param token - Token de autenticação do usuário.
 * @returns Lista de mensagens ou erro.
 */
const fetchMessages = async (chatId: string, token: string | undefined) => {
  const config = requestConfig('GET', null, token);

  try {
    const res = await fetch(`${api}/chats/${chatId}/messages`, config)
      .then((res) => res.json())
      .catch((err) => ({ error: err.message }));

    return res;
  } catch (error) {
    console.error(error);
    return { error: 'Erro ao buscar mensagens' };
  }
};

/**
 * Envia uma nova mensagem para um chat.
 * @param chatId - ID do chat.
 * @param messageData - Dados da mensagem.
 * @param token - Token de autenticação do usuário.
 * @param file - Arquivo a ser enviado (opcional).
 * @returns A mensagem enviada ou erro.
 */
const sendMessage = async (
  chatId: string,
  messageData: Partial<IMessage>,
  token: string | undefined,
  file?: File,
) => {
  if (file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('text', messageData.text || '');

    const config = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    try {
      const res = await fetch(`${api}/chats/${chatId}/messages`, config)
        .then((res) => res.json())
        .catch((err) => ({ error: err.message }));

      return res;
    } catch (error) {
      console.error(error);
      return { error: 'Erro ao enviar mensagem com arquivo' };
    }
  } else {
    // Enviar apenas texto
    const config = requestConfig('POST', messageData, token);

    try {
      const res = await fetch(`${api}/chats/${chatId}/messages`, config)
        .then((res) => res.json())
        .catch((err) => ({ error: err.message }));

      return res;
    } catch (error) {
      console.error(error);
      return { error: 'Erro ao enviar mensagem' };
    }
  }
};

/**
 * Dá like em uma mensagem específica.
 * @param chatId - ID do chat.
 * @param messageId - ID da mensagem.
 * @param token - Token de autenticação do usuário.
 * @returns A mensagem atualizada com o novo número de likes ou erro.
 */
const likeMessage = async (
  chatId: string,
  messageId: string,
  token: string | undefined,
) => {
  const config = requestConfig('POST', null, token);

  try {
    const res = await fetch(
      `${api}/chats/${chatId}/messages/${messageId}/like`,
      config,
    )
      .then((res) => res.json())
      .catch((err) => ({ error: err.message }));

    return res;
  } catch (error) {
    console.error(error);
    return { error: 'Erro ao dar like na mensagem' };
  }
};

/**
 * Faz upload de um arquivo para o chat.
 * @param chatId - ID do chat.
 * @param formData - FormData contendo o arquivo.
 * @param token - Token de autenticação do usuário.
 * @returns URL do arquivo ou erro.
 */
const uploadFile = async (
  chatId: string,
  formData: FormData,
  token: string | undefined,
) => {
  const config = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  try {
    const res = await fetch(`${api}/chats/${chatId}/upload`, config)
      .then((res) => res.json())
      .catch((err) => ({ error: err.message }));

    return res;
  } catch (error) {
    console.error(error);
    return { error: 'Erro ao fazer upload do arquivo' };
  }
};

const ChatService = {
  fetchMessages,
  sendMessage,
  likeMessage,
  uploadFile,
};

export default ChatService;
