// src/slices/ChatSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import ChatService from '../services/ChatService';
import { RootState } from '../store';

// Interfaces
import { IMessage, IChatStates } from '../Interfaces/IChat';

// Estado Inicial
const initialState: IChatStates = {
  messages: null,
  chatError: false,
  chatLoading: false,
  chatSuccess: false,
  chatMessage: '',
};

// Thunks Assíncronos

/**
 * Busca as mensagens de um chat específico.
 * @param chatId - ID do chat.
 */
export const fetchMessages = createAsyncThunk(
  'chat/fetchMessages',
  async (chatId: string, thunkAPI) => {
    try {
      const appState = thunkAPI.getState() as RootState;
      const token = appState.LoginReducer.user!;
      const res = await ChatService.fetchMessages(chatId, token);

      // Verifica se há erros na resposta
      if (res.error) {
        return thunkAPI.rejectWithValue(res.error);
      }

      return res as IMessage[];
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.message || 'Erro ao buscar mensagens',
      );
    }
  },
);

/**
 * Envia uma nova mensagem para o chat.
 * @param params - Objeto contendo chatId, messageData e file.
 */
export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async (
    {
      chatId,
      messageData,
      file,
    }: { chatId: string; messageData: Partial<IMessage>; file?: File },
    thunkAPI,
  ) => {
    try {
      const appState = thunkAPI.getState() as RootState;
      const token = appState.LoginReducer.user!;
      const res = await ChatService.sendMessage(
        chatId,
        messageData,
        token,
        file,
      );

      if (res.error) {
        return thunkAPI.rejectWithValue(res.error);
      }

      return res as IMessage;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.message || 'Erro ao enviar mensagem',
      );
    }
  },
);

// ... outros thunks permanecem inalterados
/**
 * Dá like em uma mensagem específica.
 * @param params - Objeto contendo chatId e messageId.
 */
export const likeMessage = createAsyncThunk(
  'chat/likeMessage',
  async (
    { chatId, messageId }: { chatId: string; messageId: string },
    thunkAPI,
  ) => {
    try {
      const appState = thunkAPI.getState() as RootState;
      const token = appState.LoginReducer.user!;
      const res = await ChatService.likeMessage(chatId, messageId, token);

      if (res.error) {
        return thunkAPI.rejectWithValue(res.error);
      }

      return res as IMessage;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.message || 'Erro ao dar like na mensagem',
      );
    }
  },
);

/**
 * Faz upload de um arquivo para o chat.
 * @param params - Objeto contendo chatId e formData.
 */
export const uploadFile = createAsyncThunk(
  'chat/uploadFile',
  async (
    { chatId, formData }: { chatId: string; formData: FormData },
    thunkAPI,
  ) => {
    try {
      const appState = thunkAPI.getState() as RootState;
      const token = appState.LoginReducer.user!;
      const res = await ChatService.uploadFile(chatId, formData, token);

      if (res.error) {
        return thunkAPI.rejectWithValue(res.error);
      }

      return res as { fileUrl: string };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.message || 'Erro ao fazer upload do arquivo',
      );
    }
  },
);

// Slice
export const ChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    /**
     * Reseta o estado do chat para os valores iniciais.
     */
    resetChat: (state) => {
      state.chatError = false;
      state.chatSuccess = false;
      state.chatLoading = false;
      state.chatMessage = '';
      state.messages = null;
    },
    /**
     * Adiciona uma nova mensagem ao estado.
     */
    addMessage: (state, action: PayloadAction<IMessage>) => {
      state.messages?.push(action.payload);
    },
    /**
     * Atualiza o número de likes de uma mensagem existente.
     */
    updateLikes: (state, action: PayloadAction<IMessage>) => {
      const index = state.messages?.findIndex(
        (msg) => msg._id === action.payload._id,
      );
      if (index !== undefined && index !== -1 && state.messages) {
        state.messages[index].likes = action.payload.likes;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Messages
      .addCase(
        fetchMessages.fulfilled,
        (state, action: PayloadAction<IMessage[]>) => {
          state.chatError = false;
          state.chatLoading = false;
          state.chatSuccess = true;
          state.messages = action.payload;
        },
      )
      .addCase(fetchMessages.pending, (state) => {
        state.chatError = false;
        state.chatLoading = true;
        state.chatSuccess = false;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.chatError = true;
        state.chatLoading = false;
        state.chatSuccess = false;
        state.messages = null;
        state.chatMessage =
          typeof action.payload === 'string' ? action.payload : '';
      })

      // Send Message
      .addCase(
        sendMessage.fulfilled,
        (state, action: PayloadAction<IMessage>) => {
          state.chatError = false;
          state.chatLoading = false;
          state.chatSuccess = true;
        },
      )
      .addCase(sendMessage.pending, (state) => {
        state.chatError = false;
        state.chatLoading = true;
        state.chatSuccess = false;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.chatError = true;
        state.chatLoading = false;
        state.chatSuccess = false;
        state.chatMessage =
          typeof action.payload === 'string' ? action.payload : '';
      })

      // Like Message
      .addCase(
        likeMessage.fulfilled,
        (state, action: PayloadAction<IMessage>) => {
          state.chatError = false;
          state.chatLoading = false;
          state.chatSuccess = true;
          const index = state.messages?.findIndex(
            (msg) => msg._id === action.payload._id,
          );
          if (index !== undefined && index !== -1 && state.messages) {
            state.messages[index].likes = action.payload.likes;
          }
        },
      )
      .addCase(likeMessage.pending, (state) => {
        state.chatError = false;
        state.chatLoading = true;
        state.chatSuccess = false;
      })
      .addCase(likeMessage.rejected, (state, action) => {
        state.chatError = true;
        state.chatLoading = false;
        state.chatSuccess = false;
        state.chatMessage =
          typeof action.payload === 'string' ? action.payload : '';
      })

      // Upload File
      .addCase(
        uploadFile.fulfilled,
        (state, action: PayloadAction<{ fileUrl: string }>) => {
          state.chatError = false;
          state.chatLoading = false;
          state.chatSuccess = true;
          // A URL do arquivo será usada ao enviar a mensagem
          // Nenhuma alteração direta no estado necessária aqui
        },
      )
      .addCase(uploadFile.pending, (state) => {
        state.chatError = false;
        state.chatLoading = true;
        state.chatSuccess = false;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.chatError = true;
        state.chatLoading = false;
        state.chatSuccess = false;
        state.chatMessage =
          typeof action.payload === 'string' ? action.payload : '';
      });
  },
});

// Exportando as ações e o reducer
export const { resetChat, addMessage, updateLikes } = ChatSlice.actions;
export const chatSelector = (state: RootState) => state.ChatReducer; // Assegure-se de que o reducer está registrado como 'ChatReducer' no store
export default ChatSlice.reducer;
