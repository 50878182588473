// src/contexts/MapContext.tsx
import React, { createContext, ReactNode } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

type MapContextProps = {
  isLoaded: boolean;
  loadError: any;
};

export const MapContext = createContext<MapContextProps>({
  isLoaded: false,
  loadError: null,
});

type Props = {
  children: ReactNode;
};

const libraries: ('geometry' | 'places')[] = ['geometry', 'places'];

export const MapProvider: React.FC<Props> = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries,
  });

  return (
    <MapContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </MapContext.Provider>
  );
};
