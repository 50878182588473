import React, { useState, useEffect, ChangeEvent } from 'react';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import {
  projectSelector,
  insertProjectAsync,
  deleteProjectAsync,
  getAllProjectsAsync,
  reset,
} from '../../slices/ProjectSlice';
import Loader from '../../components/Loading/Loader'; // Importando o componente Loader
import { Link } from 'react-router-dom';

const Project = () => {
  const dispatch = useAppDispatch();
  const { projects, loading, error, message, insertSuccess, deleteSuccess } =
    useAppSelector(projectSelector);

  // Estado para o arquivo de upload
  const [file, setFile] = useState<File | null>(null);
  const [showForm, setShowForm] = useState(false); // Controla visibilidade do formulário

  useEffect(() => {
    dispatch(getAllProjectsAsync());

    // Limpar estados de sucesso e erro após exibir mensagens
    return () => {
      dispatch(reset());
    };
  }, [dispatch, insertSuccess, deleteSuccess]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'zip') {
        alert('Apenas arquivos ZIP são permitidos.');
        e.target.value = ''; // Resetar o input
        setFile(null);
        return;
      }
      setFile(selectedFile);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!file) {
      alert('Por favor, selecione um arquivo para upload.');
      return;
    }

    // Criar FormData para enviar o arquivo
    const formData = new FormData();
    formData.append('file', file);

    // Dispatch para inserir o projeto
    dispatch(insertProjectAsync(formData));

    // Resetar os campos após o envio
    setFile(null);
    setShowForm(false);
  };

  const handleDelete = (id: string) => {
    if (window.confirm('Tem certeza que deseja remover este projeto?')) {
      dispatch(deleteProjectAsync(id));
    }
  };

  return (
    <div className="container mx-auto p-4">
      {/* Loader */}
      {loading && <Loader />}

      <h2 className="text-xl font-semibold mb-4">Gerenciar Projetos</h2>

      {/* Botão para abrir/fechar o formulário de cadastro */}
      <div className="mb-6">
        <button
          onClick={() => setShowForm(!showForm)}
          className="customButton"
          disabled={loading} // Desabilitar botão enquanto carrega
        >
          {showForm ? 'Fechar Cadastro' : 'Cadastrar Projeto'}
        </button>
      </div>

      {/* Formulário de Upload */}
      {showForm && (
        <div className="w-full md:w-1/2 lg:w-1/3 mb-6">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-4"
            encType="multipart/form-data"
          >
            <div>
              <label
                htmlFor="file"
                className="block text-sm font-medium text-gray-700"
              >
                Arquivo ZIP *
              </label>
              <input
                type="file"
                id="file"
                accept=".zip"
                onChange={handleFileChange}
                className="customInputFile border border-top-digital-op-40 w-full"
                required
              />
            </div>

            <div className="flex justify-end gap-2">
              <button
                type="submit"
                className="customButton"
                disabled={loading} // Desabilitar botão enquanto carrega
              >
                {loading ? 'Adicionando...' : 'Adicionar'}
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Mensagens de Sucesso ou Erro */}
      {error && (
        <div className="bg-red-100 text-red-700 p-4 rounded mb-4">
          {message}
        </div>
      )}
      {insertSuccess && (
        <div className="bg-green-100 text-green-700 p-4 rounded mb-4">
          Projeto adicionado com sucesso!
        </div>
      )}
      {deleteSuccess && (
        <div className="bg-green-100 text-green-700 p-4 rounded mb-4">
          Projeto removido com sucesso!
        </div>
      )}

      {/* Listagem de Projetos */}
      <div>
        <h3 className="text-lg font-medium mb-2">Projetos Existentes</h3>
        {projects.length === 0 ? (
          <p className="text-sm">Nenhum projeto adicionado.</p>
        ) : (
          <ul className="space-y-2">
            {projects.map((project) => (
              <li
                key={project.id}
                className="flex items-center justify-between p-2 border rounded-md"
              >
                <div>
                  <span className="font-medium">{project.name}</span>
                  <p className="text-sm text-gray-500 truncate w-48">
                    Arquivo: {project.file}
                  </p>
                  <p className="text-sm text-gray-500">
                    Bounds: N {project.bounds.north}, S {project.bounds.south},
                    E {project.bounds.east}, W {project.bounds.west}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  {/* Botão de Remover */}
                  <button
                    onClick={() => handleDelete(project.id)}
                    className="text-red-500 hover:text-red-700 text-sm"
                  >
                    Remover
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Project;
