import React, { useEffect, useState, useContext, useRef } from 'react';
import { GoogleMap, Marker, Circle, InfoWindow } from '@react-google-maps/api';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';

// Redux
import { configSelector, getConfig } from '../../slices/ConfigSystemSlice';
import { systemSelector, getSystemById } from '../../slices/SystemSlice';
import { stationSelector } from '../../slices/StationSlice';

// Interfaces
import { TSystem } from '../../Interfaces/ISystem';

// Icons
import { X } from 'lucide-react';
import { urlapp } from '../../utils/config';

// Hooks de contexto
import { useGlobalContext } from '../../hooks/useGlobalContext';

// Components
import DocumentsSystem from '../Document/DocumentsSystem';
import SystemField from './SystemField';
import Enabled from '../../components/Utils/Enabled';

// Importar o MapContext
import { MapContext } from '../../contexts/MapContext';

type Props = {
  systemId: string;
  reportDocuments?: boolean;
};

const SystemDetails = ({ systemId, reportDocuments = false }: Props) => {
  const { dispatchGlobalState } = useGlobalContext();
  const dispatch = useAppDispatch();
  const { system, nearbyStations } = useAppSelector(systemSelector);
  const { config } = useAppSelector(configSelector);
  const { station } = useAppSelector(stationSelector);

  const [checkInterference, setCheckInterference] = useState<boolean>(false);
  const [activeMarker, setActiveMarker] = useState<number | null>(null);

  let absolutePosition = 'absolute top-0 right-0';
  if (reportDocuments) {
    absolutePosition = '';
  }

  const handleCloseLabelSystemForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatchGlobalState({ type: 'CLOSE_SYSTEM_DETAILS_AND_LABEL_SYSTEM_FORM' });
  };

  useEffect(() => {
    const object = {
      systemId,
      latitude: station?.Latitude,
      longitude: station?.Longitude,
    };
    dispatch(getSystemById(object));
    dispatch(getConfig());
  }, [dispatch, systemId]);

  // Utilizar o contexto para obter isLoaded e loadError
  const { isLoaded, loadError } = useContext(MapContext);

  // Referência para o mapa
  const mapRef = useRef<google.maps.Map | null>(null);

  const radius = 10000; // 10 km

  if (!system) {
    return <div>Carregando detalhes do sistema...</div>;
  }

  return (
    <div className={`${absolutePosition} bg-white w-full h-full p-4`}>
      <div>
        <div className="flex justify-between items-center">
          <h1 className="md:text-base text-smp text-top-digital-content-color font-semibold mb-2 font-top-digital-content">
            Dados do Sistema{' '}
            {system?.Label ? (
              system.Label
            ) : (
              <span className="text-smp font-light">
                (esse sistema não possui apelido)
              </span>
            )}
          </h1>
          <div className="flex items-center justify-center gap-2">
            <div className="mb-4 text-smp flex items-center">
              Checar interferências:{' '}
              <button onClick={() => setCheckInterference(!checkInterference)}>
                <Enabled enabled={checkInterference} />
              </button>
            </div>

            {!reportDocuments && (
              <button onClick={handleCloseLabelSystemForm}>
                <X className="cursor-pointer w-4 h-4 mb-2" />
              </button>
            )}
          </div>
        </div>

        {/* Renderizar o Mapa Condicionalmente */}
        {checkInterference &&
          station &&
          !isNaN(station.Latitude) &&
          !isNaN(station.Longitude) && (
            <div className="mt-6">
              {loadError && (
                <div>Erro ao carregar o Google Maps: {loadError.message}</div>
              )}
              {!isLoaded && <div>Carregando mapa...</div>}
              {nearbyStations && nearbyStations.length === 0 ? (
                <div className="text-green-500 text-center text-smp">
                  Nenhuma estação próxima causando interferência encontrada
                </div>
              ) : (
                <div className="text-red-500 text-center text-smp">
                  Estações próximas que podem causar interferência
                </div>
              )}
              {isLoaded && (
                <GoogleMap
                  mapContainerStyle={{
                    width: '100%',
                    height: '250px',
                    border: '1px solid #4A858C', // Remova após verificar
                    borderRadius: '5px',
                  }}
                  center={{
                    lat: station.Latitude,
                    lng: station.Longitude,
                  }}
                  zoom={10}
                  options={{
                    disableDefaultUI: true,
                    zoomControl: true, // Desabilita os controles de zoom
                    draggable: true, // Desabilita o arrasto do mapa
                    scrollwheel: false, // Desabilita o zoom via scroll do mouse
                    disableDoubleClickZoom: true, // Desabilita o zoom via duplo clique
                    minZoom: 8, // Define o zoom mínimo
                    maxZoom: 16, // Define o zoom máximo (fixo)
                  }}
                  onLoad={(map) => {
                    // Assegura que mapRef está sendo atribuído corretamente
                    mapRef.current = map;
                  }}
                >
                  {/* Marcador para a Torre */}
                  <Marker
                    position={{
                      lat: station.Latitude,
                      lng: station.Longitude,
                    }}
                    title="Torre de Comunicação"
                    icon={{
                      url: `${urlapp}/img/antenna.svg`, // Caminho do ícone
                    }}
                  />

                  {/* Círculo Representando o Raio de Ação */}
                  <Circle
                    center={{
                      lat: station.Latitude,
                      lng: station.Longitude,
                    }}
                    radius={radius} // Raio em metros
                    options={{
                      strokeColor: '#4A858C',
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: '#4A858C',
                      fillOpacity: 0.35,
                    }}
                  />
                  {/* Marcadores para Estações Próximas */}
                  {nearbyStations &&
                    nearbyStations.map((station, index) => (
                      <Marker
                        key={index}
                        position={{
                          lat: parseFloat(station.Latitude), // Certifique-se de converter para número
                          lng: parseFloat(station.Longitude),
                        }}
                        title={`Estação: ${station.NomeEntidade}`}
                        icon={{
                          url: `${urlapp}/img/antenna_red.svg`, // Caminho do ícone
                        }}
                        onClick={() => setActiveMarker(index)} // Define o marcador ativo ao clicar
                      >
                        {activeMarker === index && (
                          <InfoWindow
                            position={{
                              lat: parseFloat(station.Latitude),
                              lng: parseFloat(station.Longitude),
                            }}
                            onCloseClick={() => setActiveMarker(null)} // Fecha o InfoWindow
                          >
                            <div>
                              <h3>{station.NomeEntidade}</h3>
                              <p>
                                <strong>Frequência TX:</strong>{' '}
                                {station.FreqTxMHz} MHz
                              </p>
                              <p>
                                <strong>Frequência RX:</strong>{' '}
                                {station.FreqRxMHz} MHz
                              </p>
                              <p>
                                <strong>Potência:</strong>{' '}
                                {station.PotenciaTransmissorWatts} W
                              </p>
                              <p>
                                <strong>Licenciamento:</strong>{' '}
                                {station.DataLicenciamento}
                              </p>
                            </div>
                          </InfoWindow>
                        )}
                      </Marker>
                    ))}
                </GoogleMap>
              )}
            </div>
          )}

        <DocumentsSystem systemId={systemId} />

        <div className="grid grid-cols-1 text-sm mt-4 mb-1 text-top-digital-content-color font-semibold font-top-digital-content text-left">
          Detalhes do sistema
        </div>
        {config &&
          config.map(
            (conf, index) =>
              conf.visible && (
                <div key={conf._id} className="grid grid-cols-2">
                  <SystemField
                    key={conf._id}
                    index={index}
                    label={conf.label}
                    campo={conf.campo}
                    system={system as TSystem}
                    reportDocuments={reportDocuments}
                  />
                </div>
              ),
          )}
      </div>
    </div>
  );
};

export default SystemDetails;
