// src/components/Map/Map.tsx
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from 'react';
import {
  GoogleMap,
  InfoWindow,
  MarkerF,
  KmlLayer,
} from '@react-google-maps/api';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import { isEqual } from 'lodash';
import { urlapp } from '../../utils/config';
import {
  Network,
  TBounds,
  TCoordInfo,
  TCoordinates,
  TLocation,
} from '../../Interfaces/ILocation';
import InfoWindowStations from '../../components/Map/InfoWindowStations';
import { IProject } from '../../Interfaces/IProject';
import { useGlobalContext } from '../../hooks/useGlobalContext';
import Enabled from '../../components/Utils/Enabled';
import NetworkComponent from '../../components/Map/Network';

// Hooks Redux
import useAppSelector from '../../hooks/useAppSelector';
import useAppDispatch from '../../hooks/useAppDispatch';
import {
  userPreferenceSliceSelector,
  getUserPreferences,
  setUserPreference,
} from '../../slices/UserPreferenceSlice';
import {
  projectSelector,
  clearSelectedProjectId,
  setSelectedProjectId,
  getAllProjectsAsync,
} from '../../slices/ProjectSlice';

// Importar o MapContext
import { MapContext } from '../../contexts/MapContext';

type Props = {
  locations: TLocation[];
  networks: Network[];
};

const DEFAULT_PROJECT_ZOOM = 15; // Defina o zoom padrão desejado aqui

const Map = ({ locations, networks }: Props) => {
  // Utilizar o contexto para obter isLoaded e loadError
  const { isLoaded, loadError } = useContext(MapContext);

  const mapRef = useRef<google.maps.Map | null>(null);
  const [mapon, setMapon] = useState(true);
  const [isMouseInside, setIsMouseInside] = useState(false);
  const [showPoints, setShowPoints] = useState(true);
  const [mousePosition, setMousePosition] = useState<{
    lat: number | null;
    lng: number | null;
  }>({
    lat: null,
    lng: null,
  });
  const dispatch = useAppDispatch();
  const { userPreference } = useAppSelector(userPreferenceSliceSelector);

  // Selecionar projetos do Redux
  const {
    projects,
    selectedProjectId,
    loading: projectsLoading,
    error: projectsError,
    hasFetched,
  } = useAppSelector(projectSelector);

  // Obter o projeto selecionado
  const selectedProject = useMemo(() => {
    return projects.find((project) => project.id === selectedProjectId) || null;
  }, [selectedProjectId, projects]);

  const setMapTypeToRoadmap = useCallback(() => {
    setMapon(true);
    if (mapRef.current) {
      mapRef.current.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    }
  }, []);

  const setMapTypeToSatellite = useCallback(() => {
    setMapon(false);
    if (mapRef.current) {
      mapRef.current.setMapTypeId(google.maps.MapTypeId.HYBRID);
    }
  }, []);

  const togglePoints = useCallback(() => {
    const data: { field: string; value: boolean } = {
      field: 'showLinkPoints',
      value: !userPreference.showLinkPoints,
    };
    dispatch(setUserPreference(data));
  }, [dispatch, userPreference.showLinkPoints]);

  const { globalState, dispatchGlobalState } = useGlobalContext();

  const [iniDrag, setIniDrag] = useState<TBounds | null>(null);
  const [zoom, setZoom] = useState(0);

  // Estado para Combobox de Seleção de Projetos
  const [projectSearchValue, setProjectSearchValue] = useState('');

  // Estado e manipuladores para Combobox de Pesquisa de Locais
  const {
    ready,
    value: locationSearchValue,
    suggestions: { status: locationSearchStatus, data: locationSearchData },
    setValue: setLocationSearchValue,
    clearSuggestions: clearLocationSuggestions,
    init,
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: {
      location: isLoaded
        ? new google.maps.LatLng(-14.235, -51.9253)
        : undefined, // Coordenadas aproximadas do centro do Brasil
      radius: 5 * 1000, // Definindo o raio de pesquisa em metros (5 km)
      language: 'pt-BR', // Idioma configurado para português
      componentRestrictions: { country: 'BR' }, // Restringindo a pesquisa ao Brasil
    },
    debounce: 300, // Opcional: adiciona debounce para evitar muitas requisições
  });

  useEffect(() => {
    if (isLoaded) {
      init(); // Inicializa o usePlacesAutocomplete manualmente
    }
  }, [isLoaded, init]);

  const handleProjectInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setProjectSearchValue(e.target.value);
    },
    [],
  );

  const handleLocationInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLocationSearchValue(e.target.value);
    },
    [setLocationSearchValue],
  );

  // Função para validar coordenadas
  const isValidLatLng = useCallback((lat: any, lng: any): lat is number => {
    return (
      typeof lat === 'number' &&
      !isNaN(lat) &&
      typeof lng === 'number' &&
      !isNaN(lng)
    );
  }, []);

  // Função para verificar se uma localização está visível
  const isVisible = useCallback(
    (lat: number, lng: number) => {
      return globalState.visibleLocations.some(
        (location) => location.Latitude === lat && location.Longitude === lng,
      );
    },
    [globalState.visibleLocations],
  );

  // Despachar getAllProjectsAsync() para garantir que os projetos estejam carregados
  useEffect(() => {
    if (projects.length === 0 && !projectsLoading && !hasFetched) {
      dispatch(getAllProjectsAsync());
    }
  }, [dispatch, projects.length, projectsLoading, hasFetched]);

  // Adicionar logs para depuração
  useEffect(() => {
    console.log('Projetos carregados:', projects);
  }, [projects]);

  useEffect(() => {
    console.log('Projeto selecionado:', selectedProject);
  }, [selectedProject]);

  const onLoad = useCallback(
    (mapInstance: google.maps.Map) => {
      if (!mapInstance) {
        return;
      }
      mapRef.current = mapInstance;
      const storedCoords = localStorage.getItem('coords');
      const bounds = new google.maps.LatLngBounds();
      const coords: TBounds | null = storedCoords
        ? JSON.parse(storedCoords)
        : null;

      if (coords !== null) {
        // Validar se as bounds possuem números válidos
        if (
          typeof coords.Latitude.North === 'number' &&
          typeof coords.Latitude.South === 'number' &&
          typeof coords.Longitude.East === 'number' &&
          typeof coords.Longitude.West === 'number'
        ) {
          bounds.extend(
            new google.maps.LatLng(
              coords.Latitude.North,
              coords.Longitude.East,
            ),
          );
          bounds.extend(
            new google.maps.LatLng(
              coords.Latitude.South,
              coords.Longitude.West,
            ),
          );
          mapInstance.fitBounds(bounds, 0);
        } else {
          console.error('Bounds armazenadas têm valores inválidos:', coords);
        }
      } else if (selectedProject) {
        // Centralizar no projeto selecionado
        const { north, east, south, west } = selectedProject.bounds;

        if (isValidLatLng(north, east) && isValidLatLng(south, west)) {
          bounds.extend(new google.maps.LatLng(north, east));
          bounds.extend(new google.maps.LatLng(south, west));
          mapInstance.fitBounds(bounds, 0);
          mapInstance.setZoom(DEFAULT_PROJECT_ZOOM);
        } else {
          console.error(
            `Coordenadas inválidas para o projeto ${selectedProject.id}: north=${north}, east=${east}, south=${south}, west=${west}`,
          );
        }
      } else {
        // Se não houver coordenadas armazenadas ou projeto selecionado, ajustar para todas as localizações
        locations.forEach((loc) => {
          bounds.extend(new google.maps.LatLng(loc.Latitude, loc.Longitude));
        });
        mapInstance.fitBounds(bounds, 0);
      }

      // Adicionar o listener aqui, já que mapInstance não é null
      google.maps.event.addListenerOnce(mapInstance, 'bounds_changed', () => {
        const currentZoom = mapInstance.getZoom();
        if (currentZoom !== undefined && currentZoom > 12) {
          mapInstance.setZoom(12);
        }
      });
    },
    [selectedProject, isValidLatLng, locations],
  );

  const coordInfo = useCallback((): TCoordInfo => {
    if (!mapRef.current) {
      return {
        last: null,
        current: {
          Latitude: { North: 0, South: 0 },
          Longitude: { West: 0, East: 0 },
        },
        isChanged: false,
      };
    }

    const bounds = mapRef.current.getBounds();
    if (!bounds) {
      return {
        last: null,
        current: {
          Latitude: { North: 0, South: 0 },
          Longitude: { West: 0, East: 0 },
        },
        isChanged: false,
      };
    }

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const current: TBounds = {
      Latitude: {
        North: ne.lat(),
        South: sw.lat(),
      },
      Longitude: {
        West: sw.lng(),
        East: ne.lng(),
      },
    };

    const storedCoords = localStorage.getItem('coords');
    const last: TBounds | null = storedCoords ? JSON.parse(storedCoords) : null;

    const isDragged =
      JSON.stringify(current) !== JSON.stringify(iniDrag) && iniDrag !== null;
    const hasChanged =
      JSON.stringify(current) !== JSON.stringify(last) || isDragged;

    if (hasChanged) {
      localStorage.setItem('lsStationPage', '1');
      dispatchGlobalState({ type: 'SET_STATION_PAGE', stationPage: 1 });
    }

    return {
      last,
      current,
      isChanged: hasChanged,
    };
  }, [iniDrag, dispatchGlobalState]);

  const getStationsVisible = useCallback(() => {
    if (!mapRef.current) return;

    dispatchGlobalState({ type: 'LOADING_STATION_LIST_START' });
    const info = coordInfo();

    if (info.current) {
      localStorage.setItem('coords', JSON.stringify(info.current));
    }

    const visible: TCoordinates[] = locations.filter((station) => {
      const { Latitude, Longitude } = station;
      return (
        Latitude > info.current?.Latitude.South &&
        Latitude < info.current?.Latitude.North &&
        Longitude > info.current?.Longitude.West &&
        Longitude < info.current?.Longitude.East
      );
    });

    if (
      globalState.latInfoWindow &&
      globalState.lngInfoWindow &&
      !isVisible(globalState.latInfoWindow, globalState.lngInfoWindow)
    ) {
      dispatchGlobalState({
        type: 'SET_INFOWINDOW_COORD',
        lat: null,
        lng: null,
        classesEstacao: [],
        idStation: '',
        stationPage: 1,
      });
    }

    if (
      !isEqual(globalState.visibleLocations, visible) ||
      visible.length === 0
    ) {
      dispatchGlobalState({
        type: 'SET_VISIBLE_LOCATIONS',
        visibleLocations: visible,
      });
    }

    const newZoom = mapRef.current.getZoom() || 0;
    setZoom(newZoom);
  }, [
    coordInfo,
    dispatchGlobalState,
    globalState.latInfoWindow,
    globalState.lngInfoWindow,
    globalState.visibleLocations,
    isVisible,
    locations,
  ]);

  const setMapCoordenates = useCallback(() => {
    const info = coordInfo();
    if (info.current) {
      localStorage.setItem('coords', JSON.stringify(info.current));
    }
  }, [coordInfo]);

  const setDragStart = useCallback((): void => {
    if (!mapRef.current) return;
    const bounds = mapRef.current.getBounds();
    if (!bounds) return;

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const ini: TBounds = {
      Latitude: {
        North: ne.lat(),
        South: sw.lat(),
      },
      Longitude: {
        West: sw.lng(),
        East: ne.lng(),
      },
    };
    setIniDrag(ini);
  }, []);

  const handleProjectInfoWindow = useCallback(
    (project: IProject) => {
      if (
        project.bounds.north === globalState.latInfoWindow &&
        project.bounds.east === globalState.lngInfoWindow
      ) {
        return;
      }
      dispatchGlobalState({
        type: 'SET_INFOWINDOW_COORD',
        lat: project.bounds.north,
        lng: project.bounds.east,
      });
    },
    [dispatchGlobalState, globalState.latInfoWindow, globalState.lngInfoWindow],
  );

  const handleInfoWindow = useCallback(
    (station: TLocation) => {
      if (
        station.Latitude === globalState.latInfoWindow &&
        station.Longitude === globalState.lngInfoWindow &&
        station.CodTiposClasseEstacao === globalState.classeEstacaoInfoWindow
      ) {
        return;
      }
      dispatchGlobalState({
        type: 'SET_INFOWINDOW_COORD',
        lat: station.Latitude,
        lng: station.Longitude,
        classesEstacao: station.CodTiposClasseEstacao,
      });
      if (station.stations && station.stations.length > 0) {
        dispatchGlobalState({
          type: 'SET_STATION_ID',
          idStation: station.stations[0]._id,
        });
      }
    },
    [
      dispatchGlobalState,
      globalState.latInfoWindow,
      globalState.lngInfoWindow,
      globalState.classeEstacaoInfoWindow,
    ],
  );

  const mapStyles = useMemo(
    () => ({
      styles: [
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text',
          stylers: [
            { weight: '0.4' },
            { visibility: 'on' },
            { color: mapon ? '#154449' : '#ffffff' },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry.fill',
          stylers: [{ visibility: 'on' }, { color: '#3B6A70' }, { weight: 4 }],
        },
      ],
    }),
    [mapon],
  );

  const getIcon = useCallback(
    (eq: TLocation | IProject) => {
      if ('CodTiposClasseEstacao' in eq && eq.CodTiposClasseEstacao) {
        // TLocation
        const fillColor = mapon ? '' : '-custom';
        let iconPath = `radio-tower${fillColor}.svg`;

        if (eq.CodTiposClasseEstacao.includes('ML')) {
          return {
            url: `${urlapp}/img/car${fillColor}.svg`,
            anchor: new window.google.maps.Point(-12, 0),
          };
        } else if (eq.CodTiposClasseEstacao.includes('RR')) {
          return {
            url: `${urlapp}/img/radio-tower-blue.svg`,
            anchor: new window.google.maps.Point(12, 0),
          };
        } else {
          const towerStatus = (eq as TLocation).color; // Ajuste conforme a propriedade real
          if (towerStatus === 'red') {
            iconPath = 'radio-tower-red.svg';
          } else if (towerStatus === 'orange') {
            iconPath = 'radio-tower-orange.svg';
          } else if (towerStatus === 'green') {
            iconPath = 'radio-tower-green.svg';
          }

          return {
            url: `${urlapp}/img/${iconPath}`,
            anchor: new window.google.maps.Point(12, 0),
          };
        }
      } else {
        // IProject
        return {
          url: `${urlapp}/img/project-marker.svg`, // Defina um ícone adequado para projetos
          anchor: new window.google.maps.Point(12, 0),
        };
      }
    },
    [mapon, urlapp],
  );

  const handleMouseMove = useCallback(
    (e: google.maps.MapMouseEvent) => {
      if (e.latLng && isMouseInside) {
        setMousePosition({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        });
      }
    },
    [isMouseInside],
  );

  const handleMouseOut = useCallback(() => {
    setIsMouseInside(false);
    setMousePosition({
      lat: null,
      lng: null,
    });
  }, []);

  const handleMouseOver = useCallback(() => {
    setIsMouseInside(true);
  }, []);

  useEffect(() => {
    dispatch(getUserPreferences());
  }, [dispatch]);

  useEffect(() => {
    if (userPreference.showLinkPoints !== undefined) {
      setShowPoints(userPreference.showLinkPoints);
    } else {
      setShowPoints(true);
    }
  }, [userPreference]);

  const handleSelect = useCallback(
    async (address: string) => {
      setLocationSearchValue(address, false);
      clearLocationSuggestions();

      try {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);

        if (mapRef.current) {
          const bounds = new google.maps.LatLngBounds();
          bounds.extend({ lat, lng });
          mapRef.current.fitBounds(bounds);

          const mapInstance = mapRef.current;
          google.maps.event.addListenerOnce(
            mapInstance,
            'bounds_changed',
            () => {
              const currentZoom = mapInstance.getZoom() ?? 0;
              if (currentZoom > 12) {
                mapInstance.setZoom(12);
              }
            },
          );

          mapRef.current.panTo({ lat, lng });
        }
      } catch (error) {
        console.error('Erro ao buscar localização: ', error);
      }
    },
    [clearLocationSuggestions, setLocationSearchValue],
  );

  // Centralizar no projeto selecionado via Redux
  useEffect(() => {
    if (selectedProject && mapRef.current) {
      console.log('Projeto encontrado para centralizar:', selectedProject);
      const bounds = new google.maps.LatLngBounds();
      const { north, east, south, west } = selectedProject.bounds;

      if (isValidLatLng(north, east) && isValidLatLng(south, west)) {
        bounds.extend(new google.maps.LatLng(north, east));
        bounds.extend(new google.maps.LatLng(south, west));
        mapRef.current.fitBounds(bounds, 0);
        mapRef.current.setZoom(DEFAULT_PROJECT_ZOOM);
      } else {
        console.error(
          `Coordenadas inválidas para o projeto ${selectedProject.id}: north=${north}, east=${east}, south=${south}, west=${west}`,
        );
      }

      // Limpar selectedProjectId após centralizar, se necessário
      // dispatch(clearSelectedProjectId());
    }
  }, [selectedProject, isValidLatLng]);

  // Verificar erros no carregamento da API
  if (loadError) {
    return <div>Erro ao carregar o Google Maps: {loadError.message}</div>;
  }

  // Garantir que o componente não tente renderizar o mapa antes de estar carregado
  if (!isLoaded) {
    return <p>Carregando mapa...</p>;
  }

  return (
    <>
      <div className="mb-1 flex justify-between items-end">
        <div className="space-x-2 flex items-end">
          <button
            onClick={setMapTypeToRoadmap}
            className={`w-20 h-8 ${
              mapon
                ? 'agiliza-button-selected-2'
                : 'agiliza-button-unselected-2'
            }`}
          >
            Mapa
          </button>
          <button
            onClick={setMapTypeToSatellite}
            className={`w-20 h-8 ${
              !mapon
                ? 'agiliza-button-selected-2'
                : 'agiliza-button-unselected-2'
            }`}
          >
            Satélite
          </button>
          {/* Combobox para Seleção de Projetos */}
          {projects.length > 0 && (
            <div className="ml-4 relative">
              <Combobox
                onSelect={(projectName) => {
                  const project = projects.find((p) => p.name === projectName);
                  if (project) {
                    dispatch(setSelectedProjectId(project.id));
                    setProjectSearchValue(project.name); // Definir o nome do projeto no input após a seleção
                  }
                }}
              >
                <ComboboxInput
                  placeholder="Selecionar Projeto"
                  className="customInputText w-60 h-8"
                  value={projectSearchValue}
                  onChange={handleProjectInputChange}
                />
                <ComboboxPopover>
                  {projectSearchValue && (
                    <ComboboxList>
                      {projects
                        .filter((project) =>
                          project.name
                            .toLowerCase()
                            .includes(projectSearchValue.toLowerCase()),
                        )
                        .map(({ id, name }) => (
                          <ComboboxOption key={id} value={name} />
                        ))}
                    </ComboboxList>
                  )}
                </ComboboxPopover>
              </Combobox>
            </div>
          )}
          {/* Tratamento de Erro de Projetos */}
          {projectsError && (
            <div className="ml-4 text-red-500">
              Erro ao carregar projetos: {projectsError}
            </div>
          )}
          <div className="flex items-end relative">
            <div className="flex items-center absolute left-1 border-b-1 gap-1">
              <span className="text-smp whitespace-nowrap">Exibir Links</span>
              <div onClick={togglePoints}>
                <Enabled enabled={userPreference.showLinkPoints} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-end gap-5">
          <div className="search-bar bg-white">
            {isLoaded ? (
              <Combobox onSelect={handleSelect}>
                <ComboboxInput
                  value={locationSearchValue}
                  onChange={handleLocationInputChange}
                  disabled={!ready}
                  placeholder="Pesquisar local"
                  className="customInputText w-60 h-8"
                />
                <ComboboxPopover>
                  {locationSearchStatus === 'OK' && (
                    <ComboboxList>
                      {locationSearchData.map(({ place_id, description }) => (
                        <ComboboxOption key={place_id} value={description} />
                      ))}
                    </ComboboxList>
                  )}
                </ComboboxPopover>
              </Combobox>
            ) : (
              <p>Carregando sugestões de locais...</p>
            )}
          </div>
          <div className="bg-white z-0 flex-col gap-1 justify-end">
            {isMouseInside && mousePosition.lat && mousePosition.lng ? (
              <div>
                <div className="text-smp w-32 h-5 text-left">
                  <span>Latitude:</span> {mousePosition.lat.toFixed(4) || 'N/A'}
                </div>
                <div className="text-smp w-32 h-5 text-left">
                  <span>Longitude:</span>{' '}
                  {mousePosition.lng.toFixed(4) || 'N/A'}
                </div>
              </div>
            ) : (
              <div>
                <div className="text-smp w-32 h-5 text-left">
                  <span>Latitude:</span> N/A
                </div>
                <div className="text-smp w-32 h-5 text-left">
                  <span>Longitude:</span> N/A
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-[52vh] w-full" id="map_principal">
        <GoogleMap
          mapContainerClassName="h-[52vh] w-full"
          mapContainerStyle={{
            border: '1px solid #4A858C', // Remova após verificar
            borderRadius: '5px',
          }}
          onLoad={onLoad}
          onDragEnd={getStationsVisible}
          onDragStart={setDragStart}
          onBoundsChanged={setMapCoordenates}
          onZoomChanged={getStationsVisible}
          onMouseMove={handleMouseMove}
          onMouseOut={handleMouseOut}
          onMouseOver={handleMouseOver}
          options={{ ...mapStyles, minZoom: 4, mapTypeControl: false }}
        >
          {/* Renderizar KmlLayer para o projeto selecionado */}
          {selectedProject && (
            <KmlLayer
              key={selectedProject.id}
              url={`${selectedProject.file}`}
              options={{
                preserveViewport: false, // Permite que o mapa ajuste os bounds para exibir o KML
                suppressInfoWindows: false, // Exibe as infowindows definidas no KML
                clickable: true, // Habilita a interatividade dos elementos do KML
              }}
              onLoad={(layer) => {
                layer.addListener('status_changed', () => {
                  const status = layer.getStatus();
                  if (status !== google.maps.KmlLayerStatus.OK) {
                    console.error(
                      `Erro ao carregar KML/KMZ: ${selectedProject.file} - Status: ${status}`,
                    );
                  } else {
                    console.log(
                      `KML/KMZ carregado com sucesso: ${selectedProject.file}`,
                    );
                  }
                });
              }}
            />
          )}

          {/* Renderizar MarkerF para o projeto selecionado */}
          {selectedProject && (
            <>
              {isValidLatLng(
                selectedProject.bounds.north,
                selectedProject.bounds.east,
              ) && (
                <MarkerF
                  key={selectedProject.id}
                  position={{
                    lat: selectedProject.bounds.north,
                    lng: selectedProject.bounds.east,
                  }}
                  icon={{
                    url: `${urlapp}/img/project-marker.svg`, // Defina um ícone adequado para projetos
                    anchor: new window.google.maps.Point(12, 0),
                  }}
                  onClick={() => handleProjectInfoWindow(selectedProject)}
                >
                  {globalState.latInfoWindow === selectedProject.bounds.north &&
                    globalState.lngInfoWindow ===
                      selectedProject.bounds.east && (
                      <InfoWindow
                        options={{ disableAutoPan: true }}
                        position={{
                          lat: selectedProject.bounds.north,
                          lng: selectedProject.bounds.east,
                        }}
                        onCloseClick={() => {
                          dispatchGlobalState({
                            type: 'CLEAN_INFOWINDOW_COORD',
                          });
                        }}
                      >
                        <div>{selectedProject.name}</div>
                        {/* Substitua por um componente mais detalhado se desejar */}
                      </InfoWindow>
                    )}
                </MarkerF>
              )}
            </>
          )}

          {/* Renderizar Marcadores para 'locations' */}
          {locations &&
            locations.map((eq) => {
              const { Latitude, Longitude } = eq;

              if (!isValidLatLng(Latitude, Longitude)) {
                console.error(
                  `Coordenadas inválidas para a estação: lat=${Latitude}, lng=${Longitude}`,
                );
                return null;
              }

              return (
                <MarkerF
                  key={`${Latitude}-${Longitude}-${eq.CodTiposClasseEstacao}`}
                  position={{
                    lat: Latitude,
                    lng: Longitude,
                  }}
                  icon={getIcon(eq)}
                  onClick={() => handleInfoWindow(eq)}
                >
                  {(globalState.latInfoWindow === Latitude &&
                    globalState.lngInfoWindow === Longitude) ||
                  (zoom > 30 && isVisible(Latitude, Longitude)) ? (
                    <InfoWindow
                      options={{ disableAutoPan: true }}
                      position={{ lat: Latitude, lng: Longitude }}
                      onCloseClick={() => {
                        dispatchGlobalState({
                          type: 'CLEAN_INFOWINDOW_COORD',
                        });
                      }}
                    >
                      <InfoWindowStations location={eq} />
                    </InfoWindow>
                  ) : null}
                </MarkerF>
              );
            })}

          {/* Marcador para Filtro de Coordenadas */}
          {globalState.filters.coordsFilters &&
            globalState.filters.coordsFilters.Latitude !== '' &&
            globalState.filters.coordsFilters.Longitude !== '' &&
            (() => {
              const lat = Number(globalState.filters.coordsFilters.Latitude);
              const lng = Number(globalState.filters.coordsFilters.Longitude);

              if (!isValidLatLng(lat, lng)) {
                console.error(
                  `Coordenadas inválidas para o filtro: lat=${lat}, lng=${lng}`,
                );
                return null;
              }

              return (
                <MarkerF
                  position={{ lat, lng }}
                  icon={{
                    url: `${urlapp}/img/pin.svg`,
                    fillColor: 'red',
                    scaledSize: new window.google.maps.Size(30, 30), // Ajuste o tamanho conforme necessário
                  }}
                >
                  <InfoWindow
                    options={{
                      disableAutoPan: true,
                      pixelOffset: new window.google.maps.Size(0, -20),
                    }}
                    position={{ lat, lng }}
                  >
                    <div>Aqui está seu filtro</div>
                  </InfoWindow>
                </MarkerF>
              );
            })()}
          {showPoints && (
            <NetworkComponent networks={networks} map={mapRef.current} />
          )}
        </GoogleMap>
      </div>
    </>
  );
};

export default Map;
