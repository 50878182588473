//hooks
import { useEffect, useState } from 'react';

import useAppDispatch from '../../hooks/useAppDispatch';
import Enabled from '../../components/Utils/Enabled';

import formatCNPJ from '../../utils/formatCNPJ';

import { getFisteis } from '../../slices/FistelSlice';
import {
  getUserPreferences,
  setUserPreferenceBoleto,
} from '../../slices/UserPreferenceSlice';

type Fistel = {
  NumFistel: string;
  CNPJ: string;
  NomeEstacao: string;
  UF_Endereco_Sede?: string;
  Nome_Municipio_Endereco_Sede?: string;
  Nome_Entidade_Prestadora_Servico?: string;
  Nome_Fantasia?: string;
  Empresa_Sede?: string;
  NumServico?: string;
  NomeServico?: string;
};
type UserConfig = {
  _id: string;
  listBillsFisteis: string[];
  receiveMailBillsFisteis: string[];
};

const ConfigBoleto = (props: any) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [userConfig, setUserConfig] = useState<UserConfig | null>(null);
  const [fisteis, setFisteis] = useState<Fistel[] | null>(null);

  const isEnabled = (fistel: string, fistArr: string[]) => {
    return fistArr.includes(fistel);
  };

  useEffect(() => {
    const fetchUserPreference = async () => {
      try {
        const result = await dispatch(getUserPreferences()).unwrap();
        const config: UserConfig = {
          _id: result._id,
          listBillsFisteis: result.listBillsFisteis,
          receiveMailBillsFisteis: result.receiveMailBillsFisteis,
        };
        setUserConfig(config);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };
    const fetchFisteis = async () => {
      try {
        const result = await dispatch(getFisteis()).unwrap();
        setFisteis(result);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchUserPreference();
    fetchFisteis();
  }, [dispatch]);

  useEffect(() => {
    if (userConfig && fisteis) {
      setLoading(false);
    }
  }, [userConfig, fisteis]);

  const handleToggle = async (
    fistel: string,
    fistArr: string[],
    type: string,
  ) => {
    const newList = fistArr.includes(fistel)
      ? fistArr.filter((f) => f !== fistel)
      : [...fistArr, fistel];

    let newConfig: any;
    switch (type) {
      case 'list':
        newConfig = { ...userConfig, listBillsFisteis: newList };
        break;
      case 'mail':
        newConfig = { ...userConfig, receiveMailBillsFisteis: newList };
        break;
    }

    setLoading(true);
    await dispatch(
      setUserPreferenceBoleto({
        listBillsFisteis: newConfig.listBillsFisteis,
        receiveMailBillsFisteis: newConfig.receiveMailBillsFisteis,
      }),
    );
    setUserConfig(newConfig);
  };

  return (
    <>
      <div
        className="flex-col md:flex-row md:justify-start p-2 gap-5"
        style={{ minHeight: '100vh' }}
      >
        <div className="sm:flex sm:items-center mx-auto">
          <div className="sm:flex-auto">
            <h1 className="text-smp font-semibold leading-6 top-digital-content-color">
              Configurações de Boletos
            </h1>
            <p className="mt-2 text-smp text-gray-700">
              Controle quais fisteis gostaria de visualizar na sua tela de
              listagem de boletos ou receber notificações por e-mail.
            </p>
          </div>
        </div>

        {loading ? (
          <p>Aguarde....</p>
        ) : (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300 border">
                  <thead>
                    <tr
                      className="bg-top-digital-op-40"
                      style={{ paddingLeft: '10px' }}
                    >
                      <th
                        scope="col"
                        className="py-3.5 pr-3 text-left text-smp font-semibold top-digital-content-color sm:pl-4"
                      >
                        <span className="md:block hidden">Fisteis</span>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                      >
                        Empresa/Sede
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                      >
                        Serviço
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                      >
                        CNPJ
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                      >
                        Listagem
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                      >
                        Notificações
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {userConfig &&
                      fisteis &&
                      fisteis.map((fistel, index) => (
                        <tr
                          key={index}
                          className={`${
                            !(index % 2) ? 'bg-white' : 'bg-top-digital-op-25'
                          } text-top-digital-content-color p-1`}
                        >
                          <td className="whitespace-nowrap py-4 pr-3 text-smp font-medium top-digital-content-color sm:pl-4">
                            {fistel.NumFistel}
                          </td>
                          <td className="whitespace-nowrap py-4 pr-3 text-smp font-medium top-digital-content-color sm:pl-0">
                            {fistel.Empresa_Sede}
                          </td>
                          <td className="whitespace-nowrap py-4 pr-3 text-smp font-medium top-digital-content-color sm:pl-0">
                            {fistel.NumServico} - {fistel.NomeServico}
                          </td>
                          <td className="whitespace-nowrap py-4 pr-3 text-smp font-medium top-digital-content-color sm:pl-0">
                            {formatCNPJ(fistel.CNPJ)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                            <span
                              title="Avançar"
                              className={`${
                                loading
                                  ? 'cursor-not-allowed opacity-50'
                                  : 'cursor-pointer'
                              }`}
                              onClick={() => {
                                if (!loading) {
                                  handleToggle(
                                    fistel.NumFistel,
                                    userConfig.listBillsFisteis,
                                    'list',
                                  );
                                }
                              }}
                            >
                              <Enabled
                                key={`enable-list-${index}`}
                                enabled={isEnabled(
                                  fistel.NumFistel,
                                  userConfig.listBillsFisteis,
                                )}
                              />
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                            <span
                              title="Avançar"
                              className={`${
                                loading
                                  ? 'cursor-not-allowed opacity-50'
                                  : 'cursor-pointer'
                              }`}
                              onClick={() => {
                                if (!loading) {
                                  handleToggle(
                                    fistel.NumFistel,
                                    userConfig.receiveMailBillsFisteis,
                                    'mail',
                                  );
                                }
                              }}
                            >
                              <Enabled
                                key={`enable-notification-${index}`}
                                enabled={isEnabled(
                                  fistel.NumFistel,
                                  userConfig.receiveMailBillsFisteis,
                                )}
                              />
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ConfigBoleto;
