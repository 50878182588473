import { io, Socket } from 'socket.io-client';

// Substitua pela URL do seu backend
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'http://localhost:5000';

// Inicialize a conexão Socket.IO com autoConnect: true
const socket: Socket = io(SOCKET_URL, {
  autoConnect: true, // Conectar automaticamente
  // path: '/socket.io/', // Não é necessário especificar, usa o padrão
});

// Adicionar logs para monitorar a conexão
socket.on('connect', () => {
  console.log('Conectado ao Socket.io:', socket.id);
});

socket.on('connect_error', (err) => {
  console.error('Erro na conexão do Socket.io:', err);
});

export default socket;
