// src/components/Boleto/Boleto.tsx
import React, { useEffect, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import convertMongoDateToBRFormat from '../../utils/dateBR';
import formatCNPJ from '../../utils/formatCNPJ';
import formatNumbers from '../../utils/formatNumber';
import checkBarCode from '../../utils/checkBarCode';

import { getAllBoletos, boletoSelector } from '../../slices/BoletoSlice';

import { TBoletoData } from '../../Interfaces/IBoletoData';
import { Table } from './Table';
import DatePaymentSelect from './DatePaymentSelect';
import BoletoStatus from './BoletoStatus';

const Boleto = () => {
  const dispatch = useAppDispatch();
  const { boletoData, lastDateCheckBoletos } = useAppSelector(boletoSelector);

  useEffect(() => {
    dispatch(getAllBoletos());
  }, [dispatch]);

  const situacao: string = 'situacao';
  const linhaDigitavel: string = 'linhaDigitavel';

  const columns = useMemo<ColumnDef<TBoletoData>[]>(
    () => [
      {
        header: 'Link',
        accessorKey: 'nossoNumero',
        cell: (info) => (
          <DatePaymentSelect
            nossoNumero={info.getValue()}
            situacao={info.row.getValue(situacao)}
          />
        ),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Receita',
        accessorKey: 'receita',
        cell: (info) => info.getValue(),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'NN',
        accessorKey: 'nossoNumero',
        cell: (info) => info.getValue(),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'CNPJ',
        accessorKey: 'CNPJ',
        cell: (info) => formatCNPJ(info.getValue()),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Número Fistel',
        accessorKey: 'NumFistel',
        cell: (info) => info.getValue(),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Serviço',
        accessorKey: 'servico',
        cell: (info) => info.getValue(),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Processo',
        accessorKey: 'processo',
        cell: (info) => info.getValue(),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Data de Vencimento',
        accessorKey: 'dataVencimento',
        cell: (info) => convertMongoDateToBRFormat(info.getValue()),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Data de Pagamento',
        accessorKey: 'dataPagamento',
        cell: (info) => convertMongoDateToBRFormat(info.getValue()),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Situação',
        accessorKey: 'situacao',
        cell: (info) => (
          <BoletoStatus
            situacao={info.getValue()}
            key={info.row.getValue(linhaDigitavel)}
          />
        ),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Valor Total',
        accessorKey: 'valorTotal',
        cell: (info) => formatNumbers.formatNumberToBRL(info.getValue()),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Débito',
        accessorKey: 'valorPrincipal',
        cell: (info) => formatNumbers.formatNumberToBRL(info.getValue()),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Mora/Multa/Juros',
        accessorKey: 'valorMoraMultaJuros',
        cell: (info) => formatNumbers.formatNumberToBRL(info.getValue()),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Outros Acréscimos',
        accessorKey: 'valorOutrosAcrescimos',
        cell: (info) => formatNumbers.formatNumberToBRL(info.getValue()),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Descontos/Abatimentos',
        accessorKey: 'valorDescontosAbatimentos',
        cell: (info) => formatNumbers.formatNumberToBRL(info.getValue()),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Outras Deduções',
        accessorKey: 'valorOutrasDeducoes',
        cell: (info) => formatNumbers.formatNumberToBRL(info.getValue()),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Linha Digitável',
        accessorKey: 'linhaDigitavel',
        cell: (info) => checkBarCode(info.getValue()),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Ano Referência',
        accessorKey: 'anoReferencia',
        cell: (info) => checkBarCode(info.getValue()),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Razão Social',
        accessorKey: 'Nome_Entidade_Prestadora_Servico',
        cell: (info) => info.getValue(),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Nome Fantasia',
        accessorKey: 'Nome_Fantasia',
        cell: (info) => info.getValue(),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'Município',
        accessorKey: 'Nome_Municipio_Endereco_Sede',
        cell: (info) => info.getValue(),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
      {
        header: 'UF',
        accessorKey: 'UF_Endereco_Sede',
        cell: (info) => info.getValue(),
        enableSorting: true,
        enableColumnFilter: false,
        filterFn: 'includesString',
      },
    ],
    [situacao, linhaDigitavel],
  );

  const data: TBoletoData[] = boletoData || [];

  return (
    <div className="mt-2 flex flex-col items-center">
      <div className="mt-2 flex flex-col items-start w-full">
        <div className="flex-col items-start">
          <div className="font-semibold font-top-digital-content text-xl md:text-lg text-top-digital-content-color mt-2 md:leading-none">
            <h2>Boletos</h2>
          </div>
          <div className="font-top-digital-content text-smp md:text-lg text-top-digital-content-color mb-0 md:leading-none">
            <span className="text-smp">Consultas de Débitos de Fistel</span>
          </div>
          <div className="font-top-digital-content text-smp md:text-lg text-top-digital-content-color mb-0 md:leading-none">
            {lastDateCheckBoletos && (
              <span className="text-smp">
                Data da última consulta: {lastDateCheckBoletos}{' '}
              </span>
            )}
          </div>
        </div>
        <div className="relative w-full" style={{ minHeight: '35px' }}>
          <div className="absolute inset-0 flex justify-center items-center">
            {/* Componentes de carregamento, se necessário */}
          </div>
        </div>
      </div>

      <Table data={data} columns={columns} />
    </div>
  );
};

export default Boleto;
