// src/utils/reactTableFn.ts
import { rankItem, rankings } from '@tanstack/match-sorter-utils';
import type { RankingInfo } from '@tanstack/match-sorter-utils';
import type { Row } from '@tanstack/react-table';

// Função fuzzy adaptada para verificar se o valor é nulo ou indefinido
const fuzzy = <TData extends Record<string, any> = {}>(
  row: Row<TData>,
  columnId: string,
  filterValue: string | number,
  addMeta: (meta: { itemRank: RankingInfo }) => void, // Ajuste aqui
) => {
  const rawValue = row.getValue(columnId);
  const value = rawValue != null ? rawValue.toString() : '';
  const itemRank = rankItem(value, filterValue as string, {
    threshold: rankings.MATCHES,
  });
  addMeta({ itemRank }); // Ajuste aqui
  return itemRank.passed;
};

fuzzy.autoRemove = (val: any) => !val;

// Função contains adaptada para verificar se o valor é nulo ou indefinido
const contains = <TData extends Record<string, any> = {}>(
  row: Row<TData>,
  id: string,
  filterValue: string | number,
) => {
  const rawValue = row.getValue(id);
  const value = rawValue != null ? rawValue.toString() : '';
  return value.toLowerCase().includes(filterValue.toString().toLowerCase());
};

contains.autoRemove = (val: any) => !val;

// Função startsWith adaptada para verificar se o valor é nulo ou indefinido
const startsWith = <TData extends Record<string, any> = {}>(
  row: Row<TData>,
  id: string,
  filterValue: string | number,
) => {
  const rawValue = row.getValue(id);
  const value = rawValue != null ? rawValue.toString() : '';
  return value.toLowerCase().startsWith(filterValue.toString().toLowerCase());
};

startsWith.autoRemove = (val: any) => !val;

// Função para filtrar números maiores ou iguais a um valor
const numberFilter = <TData extends Record<string, any> = {}>(
  row: Row<TData>,
  id: string,
  filterValue: string | number,
) => {
  const rawValue = row.getValue(id);
  const numberValue =
    typeof rawValue === 'number' ? rawValue : parseFloat(rawValue as string);
  const filterNumber =
    typeof filterValue === 'number' ? filterValue : parseFloat(filterValue);
  if (isNaN(numberValue) || isNaN(filterNumber)) return false;
  return numberValue >= filterNumber;
};

numberFilter.autoRemove = (val: any) => !val;

// Função para filtrar datas (após a data fornecida)
const dateFilter = <TData extends Record<string, any> = {}>(
  row: Row<TData>,
  id: string,
  filterValue: string | number,
) => {
  const rawValue = row.getValue(id);
  const rowDate = rawValue ? new Date(rawValue as string) : null;
  const filterDate = filterValue ? new Date(filterValue as string) : null;
  if (
    !rowDate ||
    !filterDate ||
    isNaN(rowDate.getTime()) ||
    isNaN(filterDate.getTime())
  )
    return false;
  return rowDate >= filterDate;
};

dateFilter.autoRemove = (val: any) => !val;

export const filterFns = {
  fuzzy,
  contains,
  startsWith,
  numberFilter,
  dateFilter,
};
