// src/services/ProjectService.ts
import { api, requestConfig } from '../utils/config';
import { IProject } from '../Interfaces/IProject';

const handleResponse = async (res: Response) => {
  const result = await res.json();
  if (!res.ok) {
    throw new Error(result.errors?.[0] || 'Ocorreu um erro na operação.');
  }
  return result;
};

// Inserir Projeto
const insertProject = async (
  formData: FormData,
  token: string | undefined,
): Promise<IProject> => {
  const config = requestConfig('POST', formData, token, true); // true para multipart/form-data
  try {
    const res = await fetch(`${api}/projects/uploadfile`, config);
    const data = await handleResponse(res);
    return data as IProject;
  } catch (error: any) {
    throw error;
  }
};

// Atualizar Projeto
const updateProject = async (
  id: string,
  data: Partial<IProject>,
  token: string | undefined,
): Promise<IProject> => {
  const config = requestConfig('PUT', data, token);
  try {
    const res = await fetch(`${api}/projects/${id}`, config);
    const updatedProject = await handleResponse(res);
    return updatedProject as IProject;
  } catch (error: any) {
    throw error;
  }
};

// Deletar Projeto
const deleteProject = async (
  id: string,
  token: string | undefined,
): Promise<{ message: string }> => {
  const config = requestConfig('DELETE', null, token);
  try {
    const res = await fetch(`${api}/projects/${id}`, config);
    const response = await handleResponse(res);
    return { message: response.message };
  } catch (error: any) {
    throw error;
  }
};

// Buscar Todos os Projetos
const getAllProjects = async (
  token: string | undefined,
): Promise<IProject[]> => {
  const config = requestConfig('GET', null, token);
  try {
    const res = await fetch(`${api}/projects`, config);
    const projects = await handleResponse(res);
    return projects as IProject[];
  } catch (error: any) {
    throw error;
  }
};

// Buscar Projeto por ID
const getProjectById = async (
  id: string,
  token: string | undefined,
): Promise<IProject> => {
  const config = requestConfig('GET', null, token);
  try {
    const res = await fetch(`${api}/projects/${id}`, config);
    const project = await handleResponse(res);
    return project as IProject;
  } catch (error: any) {
    throw error;
  }
};

const ProjectService = {
  insertProject,
  updateProject,
  deleteProject,
  getAllProjects,
  getProjectById,
};

export default ProjectService;
