import { api, requestConfig } from '../utils/config';

const checkFistel = async (data: any): Promise<any> => {
  const config = requestConfig('POST', data);

  try {
    const res = await fetch(api + '/fistel', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    return error;
  }
};

const getFisteis = async (token: string | undefined) => {
  const config = requestConfig('GET', null, token);

  try {
    const res = await fetch(api + '/fistel', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    return error;
  }
};

const FistelService = {
  checkFistel,
  getFisteis,
};

export default FistelService;
