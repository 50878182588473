import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { SocketProvider } from './contexts/SocketContext';

//redux
import { Provider } from 'react-redux';
import { store } from './store';

// Importar o MapProvider
import { MapProvider } from './contexts/MapContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <MapProvider>
      <SocketProvider>
        <App />
      </SocketProvider>
    </MapProvider>
  </Provider>,
  // </React.StrictMode>
);
