// src/components/Boleto/Table.tsx
import React, { useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
  ColumnDef,
  FilterFn,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import { DebouncedInput } from './DebouncedInput';
import { ColumnFilter } from './ColumnFilter';
import { filterFns } from '../../utils/reactTableFn';
import { TBoletoData, TBoletoExcelData } from '../../Interfaces/IBoletoData';
import formatCNPJ from '../../utils/formatCNPJ';
import formatNumbers from '../../utils/formatNumber';
import DocIcon from '../../components/Utils/DocIcon';

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  showFooter?: boolean;
  showNavigation?: boolean;
  showGlobalFilter?: boolean;
  filterFn?: FilterFn<T>;
}

const XLSX = require('sheetjs-style');

export const Table = <T extends object>({
  data,
  columns,
  showFooter = false,
  showNavigation = true,
  showGlobalFilter = true,
  filterFn = filterFns.contains, // Função de filtro padrão
}: ReactTableProps<T>) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting,
      columnFilters,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    filterFns,
    globalFilterFn: filterFn,
    debugTable: false,
  });

  // Função para exportar os dados para Excel
  const exportToExcel = (filteredData: TBoletoData[]) => {
    const selectedData: TBoletoExcelData[] = filteredData.map(
      ({
        NumFistel,
        CNPJ,
        dataVencimento,
        dataPagamento,
        anoReferencia,
        servico,
        receita,
        valorPrincipal,
        valorDescontosAbatimentos,
        valorOutrasDeducoes,
        valorMoraMultaJuros,
        valorJurosEncargos,
        valorOutrosAcrescimos,
        valorTotal,
        situacao,
        Nome_Entidade_Prestadora_Servico,
        Nome_Fantasia,
        Nome_Municipio_Endereco_Sede,
        UF_Endereco_Sede,
      }) => ({
        NumFistel,
        CNPJ,
        dataVencimento,
        dataPagamento,
        anoReferencia,
        servico,
        receita,
        valorPrincipal,
        valorDescontosAbatimentos,
        valorOutrasDeducoes,
        valorMoraMultaJuros,
        valorJurosEncargos,
        valorOutrosAcrescimos,
        valorTotal,
        situacao,
        Nome_Entidade_Prestadora_Servico,
        Nome_Fantasia,
        Nome_Municipio_Endereco_Sede,
        UF_Endereco_Sede,
      }),
    );

    const columnTitles: { [K in keyof TBoletoExcelData]: string } = {
      NumFistel: 'Número Fistel',
      CNPJ: 'CNPJ',
      dataVencimento: 'Data de Vencimento',
      dataPagamento: 'Data de Pagamento',
      anoReferencia: 'Ano de Referência',
      servico: 'Serviço',
      receita: 'Receita',
      valorPrincipal: 'Valor do Documento',
      valorDescontosAbatimentos: 'Descontos/Abatimentos',
      valorOutrasDeducoes: 'Outras Deduções',
      valorMoraMultaJuros: 'Mora/Multa/Juros',
      valorJurosEncargos: 'Juros/Encargos',
      valorOutrosAcrescimos: 'Outros Acréscimos',
      valorTotal: 'Valor Total',
      situacao: 'Situação',
      Nome_Entidade_Prestadora_Servico: 'Entidade Prestadora',
      Nome_Fantasia: 'Nome Fantasia',
      Nome_Municipio_Endereco_Sede: 'Município',
      UF_Endereco_Sede: 'UF',
    };

    const processedData = selectedData.map((item) => {
      const newRow: any = {};
      for (const key in columnTitles) {
        if (key in item) {
          const title = columnTitles[key as keyof TBoletoExcelData];
          newRow[title] = String(item[key as keyof TBoletoExcelData]);
        }
      }
      return newRow;
    });

    const headerRow: { [key: string]: string } = Object.fromEntries(
      Object.entries(columnTitles).map(([key, title]) => [title, key]),
    );
    processedData.unshift(headerRow);

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(processedData, { skipHeader: true });

    // Definir larguras das colunas
    ws['!cols'] = [
      { wch: 16 }, // Número Fistel
      { wch: 19 }, // CNPJ
      { wch: 21 }, // Data de Vencimento
      { wch: 21 }, // Data de Pagamento
      { wch: 18 }, // Ano de Referência
      { wch: 8 }, // Serviço
      { wch: 76 }, // Receita
      { wch: 24 }, // Valor do Documento
      { wch: 24 }, // Descontos/Abatimentos
      { wch: 24 }, // Outras Deduções
      { wch: 24 }, // Mora/Multa/Juros
      { wch: 24 }, // Juros/Encargos
      { wch: 24 }, // Outros Acréscimos
      { wch: 24 }, // Valor Total
      { wch: 28 }, // Situação
      { wch: 50 }, // Entidade Prestadora
      { wch: 50 }, // Nome Fantasia
      { wch: 33 }, // Município
      { wch: 21 }, // UF
    ];

    // Definição de estilos de células
    let range: any = { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };
    if (typeof ws['!ref'] === 'string') {
      range = XLSX.utils.decode_range(ws['!ref']);
    }
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        let cell = ws[cell_ref];

        const borderStyle = {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        };

        if (R === 0) {
          const styleHeader = {
            font: {
              bold: false,
              color: { rgb: 'FFFFFF' },
            },
            fill: {
              patternType: 'solid',
              fgColor: { rgb: '4A858C' },
            },
            alignment: {
              horizontal: 'right',
            },
            border: { ...borderStyle },
          };
          if (cell) {
            cell.s = styleHeader;
          }
        } else {
          if (cell) {
            cell.s = {
              alignment: {
                horizontal: 'right',
              },
              border: { ...borderStyle },
            };
          }
        }

        if (cell && cell.v != null) {
          switch (cell_ref.charAt(0)) {
            case 'A': // Número Fistel
              cell.t = 'n';
              break;
            case 'B': // CNPJ
              cell.t = 's';
              break;
            case 'C': // Data de Vencimento
              cell.t = 'd';
              cell.z = 'dd/mm/yyyy';
              break;
            case 'D': // Data de Pagamento
              if (cell.v !== 'Aguardando pagamento') {
                cell.t = 'd';
                cell.z = 'dd/mm/yyyy';
              } else {
                cell.t = 's';
              }
              break;
            case 'E': // Ano de Referência
              cell.t = 's';
              break;
            case 'F': // Serviço
              //cell.t = "s";
              break;
            case 'G': // Receita
              cell.t = 's';
              break;
            case 'H': // Valor do Documento
            case 'I': // Descontos/Abatimentos
            case 'J': // Outras Deduções
            case 'K': // Mora/Multa/Juros
            case 'L': // Juros/Encargos
            case 'M': // Outros Acréscimos
            case 'N': // Valor Total
              cell.t = 'n';
              cell.z = 'R$ #,##0.00';
              break;
            case 'O': // Situação
            case 'P': // Entidade Prestadora
            case 'Q': // Nome Fantasia
            case 'R': // Município
            case 'S': // UF
              cell.t = 's';
              break;
            default:
              break;
          }
        }
      }
    }

    const agora = new Date();
    const dia = agora.getDate();
    const mes = agora.getMonth() + 1;
    const ano = agora.getFullYear();

    let filter = '';
    if (globalFilter.trim() !== '') {
      filter =
        globalFilter
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '') // Remove acentos
          .replace(/ç/g, 'c')
          .replace(/Ç/g, 'C') // Substitui cedilha
          .replace(/[^a-zA-Z0-9 ]/g, '') // Remove outros caracteres especiais
          .replace(/ /g, '_')
          .toLowerCase() + // Substitui espaços por underline
        '_' +
        '.pdf';
    }

    const sheetName = `Boletos ${dia}-${mes}-${ano}`;
    const fileName = `agiliza_boletos_${
      filter !== '' ? `${filter}_` : ''
    }${dia}_${mes}_${ano}.xlsx`;

    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, fileName);
  };

  const handleExportClick = () => {
    const filteredData = table
      .getFilteredRowModel()
      .rows.map((row) => row.original as TBoletoData);
    exportToExcel(filteredData);
  };

  return (
    <>
      {showNavigation ? (
        <>
          <div className="h-2 mt-5" />
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center justify-center gap-2">
              <div className="overflow-hidden p-2">
                {showGlobalFilter ? (
                  <DebouncedInput
                    value={globalFilter ?? ''}
                    onChange={(value) => setGlobalFilter(String(value))}
                    className="font-lg border-block border p-2 shadow mb-2"
                    placeholder="Buscar em todas as colunas..."
                  />
                ) : null}
              </div>
              <div
                onClick={handleExportClick}
                className="cursor-pointer"
                title="Exportar para Excel"
              >
                <DocIcon extension=".xls" />
              </div>
            </div>
            <div className="flex items-center justify-between bg-white px-4 py-3 text-smp">
              <div>
                <nav
                  className="items-center justify-between isolate text-smp inline-flex -space-x-px rounded-none"
                  aria-label="Pagination"
                >
                  <button
                    className="relative inline-flex items-center h-8 rounded-l-md px-2 py-0 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <span className="sr-only">Primeira</span>
                    <ChevronDoubleLeftIcon
                      className="h-3 w-4"
                      aria-hidden="true"
                    />
                  </button>
                  <button
                    className="relative inline-flex items-center px-2 py-2 h-8 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <span className="sr-only">Anterior</span>
                    <ChevronLeftIcon className="h-3 w-4" aria-hidden="true" />
                  </button>
                  <button
                    className="relative inline-flex items-center px-2 h-8 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    <span className="sr-only">Próxima</span>
                    <ChevronRightIcon className="h-3 w-4" aria-hidden="true" />
                  </button>
                  <button
                    className="relative inline-flex items-center rounded-r-md px-2 h-8 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    <span className="sr-only">Última</span>
                    <ChevronDoubleRightIcon
                      className="h-3 w-4"
                      aria-hidden="true"
                    />
                  </button>
                  <div className="pl-2 flex flex-1 items-center justify-between">
                    <p className="text-smp text-gray-700">
                      <span className="font-medium">
                        Página {table.getState().pagination.pageIndex + 1}{' '}
                      </span>{' '}
                      de {table.getPageCount()}
                    </p>
                  </div>
                  <div className="flex justify-center items-center pl-2">
                    <select
                      className="customSelect mt-0"
                      value={table.getState().pagination.pageSize}
                      onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                      }}
                    >
                      {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Mostrar {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="h-4" />
                </nav>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="mt-2 h-full w-full overflow-y-auto">
        <table className="text-left font-light w-auto">
          <thead className="border-b bg-top-digital-op-40 font-medium dark:border-neutral-500">
            {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
              <tr key={`${headerGroup.id}-${headerGroupIndex}`}>
                {headerGroup.headers.map((header, headerIndex) => (
                  <th
                    key={`${header.id}-${header.column.id}-${headerIndex}`}
                    className="px-4 py-4 whitespace-nowrap font-top-digital-content font-semibold text-smp"
                  >
                    <div
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                        style: {
                          cursor: header.column.getCanSort()
                            ? 'pointer'
                            : 'default',
                        },
                        className: 'flex items-center justify-between',
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {header.column.getCanSort() ? (
                            <span>
                              {{
                                asc: ' 🔼',
                                desc: ' 🔽',
                              }[header.column.getIsSorted() as string] ?? null}
                            </span>
                          ) : null}
                        </>
                      )}
                    </div>
                    {/* Renderizar o filtro se estiver habilitado */}
                    {header.column.getCanFilter() ? (
                      <div className="mt-2">
                        <ColumnFilter column={header.column} />
                      </div>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr
                key={`${row.id}_${index}`}
                className={`${
                  !(index % 2) ? 'bg-white' : 'bg-top-digital-op-25'
                } text-top-digital-content-color p-1`}
              >
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <td
                    className="px-4 py-4 whitespace-nowrap font-top-digital-content font-normal text-smp"
                    key={`${cell.id}_${cellIndex}`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            {table.getRowModel().rows.length === 0 && (
              <tr>
                <td colSpan={columns.length} className="px-4 py-4 text-center">
                  Nenhum dado encontrado.
                </td>
              </tr>
            )}
          </tbody>
          {showFooter ? (
            <tfoot className="border-t bg-gray-50">
              {table.getFooterGroups().map((footerGroup, footerGroupIndex) => (
                <tr key={`${footerGroup.id}-${footerGroupIndex}`}>
                  {footerGroup.headers.map((header, headerIndex) => (
                    <th
                      key={`${header.id}-${headerIndex}`}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext(),
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          ) : null}
        </table>
      </div>
    </>
  );
};
