// src/components/Boleto/ColumnFilter.tsx
import React from 'react';
import { Column } from '@tanstack/react-table';
import { DebouncedInput } from './DebouncedInput';

interface ColumnFilterProps<TData> {
  column: Column<TData, any>;
}

export const ColumnFilter = <TData,>({ column }: ColumnFilterProps<TData>) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <DebouncedInput
      value={(columnFilterValue ?? '') as string}
      onChange={(value) => column.setFilterValue(value)}
      placeholder={`Filtrar ${column.id}`}
      className="mt-1 p-1 border rounded w-full text-xs"
    />
  );
};
