import { Link, useParams } from 'react-router-dom';

//icons
import { MessageSquare } from 'lucide-react';

//redux
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';

//hooks
import { useEffect } from 'react';

//context
import { useGlobalContext } from '../../hooks/useGlobalContext';

//redux
//primeira parte do componente: config e station
import { getVisibleFields } from '../../slices/ConfigStationSlice';
import { stationSelector, getStationById } from '../../slices/StationSlice';
import { documentSelector } from '../../slices/DocumentSlice';

import { systemSelector } from '../../slices/SystemSlice';
import { getDocTypes } from '../../slices/DocumentTypeSlice';

//pages
import System from '../System/System';
import SystemLinkDocument from '../System/SystemLinkDocument';
import EditStationLabel from '../../components/Station/EditStationLabel';
import SystemDetails from '../System/SystemDetails';
import StationDetails from '../../components/Station/StationDetails';
import EditSystemLabel from '../../components/System/EditSystemLabel';
import Documents from '../../components/Document/Documents';
import Chat from '../../components/Chat/Chat';

const StationPage = () => {
  /**
   * A station page é um componentes que são:
   * 1 - A própria StationPage que faz inicializações e chama os componentes abaixo
   * 2 - StationDetails que mostra os detalhes da estação através de dispatch(getStationById(id)) e seus campos em dispatch(getVisibleFields());
   */

  const { globalState, dispatchGlobalState } = useGlobalContext();

  //redux system
  const { success: successSystem } = useAppSelector(systemSelector);
  const { deleteSuccess } = useAppSelector(documentSelector);

  /*INICIO PRIMEIRA PARTE DO COMPOMENTE*/
  //id da station
  let { id } = useParams();

  if (!id) {
    id = '';
  }

  //distatchs da parte inicial do componente
  const dispatch = useAppDispatch();
  //selecionando os atributos de config e station para ser usado

  const { station, stationSuccess } = useAppSelector(stationSelector);

  //chamando o reducer para config
  useEffect(() => {
    dispatch(getVisibleFields());
  }, [dispatch]);

  //chamando o reducer para station
  useEffect(() => {
    dispatch(getStationById(id));
  }, [
    id,
    dispatch,
    stationSuccess,
    globalState.openedLabelStationForm,
    globalState.openedUploadForm,
    deleteSuccess,
  ]);

  useEffect(() => {
    dispatch(getStationById(id));
    dispatchGlobalState({ type: 'RESET_ALL' });
  }, [dispatch, id, dispatchGlobalState]);
  /*FIM PRIMEIRA PARTE*/

  useEffect(() => {
    const stationObj = {
      stationId: '',
      systemId: '',
      uploadDocumentModal: false,
    };
    dispatch(getDocTypes(stationObj));
  }, [dispatch]);

  return (
    <div className="min-h-full">
      <div className="flex items-center gap-2">
        <div className="pl-2 mt-4 text-top-digital-content-color font-semibold font-top-digital-content text-xl">
          {station?.Label && station?.Label !== ''
            ? station.Label
            : station?.EnderecoEstacao}{' '}
        </div>
        <div className="mt-4">
          <Chat chatId={id} />
        </div>
      </div>
      <div className="pl-2 mt-2 mb-10 text-top-digital-content-color font-normal font-top-digital-content text-sm tracking-widest">
        <Link to="/">Home</Link> &bull;{' '}
        {station?.Label && station?.Label !== ''
          ? station.Label
          : station?.EnderecoEstacao}{' '}
      </div>
      <div className="mt-2">
        <div className="min-w-full">
          {/* Main 3 column grid */}
          <div
            className={`grid grid-cols-1 gap-2 ${
              globalState.openFullScreenDocuments
                ? 'lg:grid-cols-1 '
                : 'lg:grid-cols-2'
            }`}
          >
            {/* Left column */}
            <div
              className={`${
                globalState.openFullScreenDocuments
                  ? 'hidden'
                  : 'overflow-auto h-[360px] border border-top-digital-primary-light rounded-lg'
              }`}
            >
              <div className="p-1 m-1">
                <div className="relative">
                  {/* conteúdo esquerda */}
                  <StationDetails />
                  {globalState.openSystemLinkForm && <SystemLinkDocument />}
                  {station && globalState.openedLabelStationForm && (
                    <EditStationLabel />
                  )}
                  {station && globalState.openedLabelSystemForm && (
                    <EditSystemLabel />
                  )}
                  {station && globalState.openedSystemDetails && (
                    <SystemDetails systemId={globalState.idSystem} />
                  )}
                </div>
              </div>
            </div>

            {/* Right column */}
            <div
              className={`border border-top-digital-primary-light rounded-lg overflow-auto ${
                globalState.openFullScreenDocuments
                  ? 'min-h-screen flex flex-col'
                  : 'h-[360px]'
              }`}
            >
              <div className="p-1 m-1">
                <div
                  className={`${
                    globalState.openFullScreenDocuments
                      ? 'w-full'
                      : 'p-2 relative'
                  }`}
                >
                  {/* conteúdo direita */}
                  <div
                    className={`bg-white px-2 sm:px-3 lg:px-4 order-1 md:order-2 ${
                      globalState.openedLabelSystemForm ||
                      globalState.openedLabelStationForm ||
                      globalState.openedSystemDetails
                        ? 'hidden md:block'
                        : ''
                    }`}
                  >
                    <Documents />
                  </div>
                </div>
              </div>
            </div>

            {/* Bellow column */}
            <div
              className={`${
                globalState.openFullScreenDocuments
                  ? 'hidden'
                  : 'lg:col-span-2 border-t mt-2'
              }`}
            >
              <section aria-labelledby="section-2-title">
                <div className="mt-2">
                  <div className="mb-3">
                    <System stationId={id} refreshSystems={successSystem} />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StationPage;
