// src/components/Boleto/DebouncedInput.tsx
import React, { useEffect, useState } from 'react';

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value: string;
  onChange: (val: string | number) => void;
  debounceTime?: number;
}

export const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounceTime = 300,
  ...props
}: Props) => {
  const [value, setValue] = useState(initialValue);

  // Atualiza o valor se o initialValue mudar
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // Debounce do onChange
  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounceTime);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, onChange, debounceTime]);

  return (
    <input
      type="text"
      {...props}
      className={`customInputText ${props.className}`} // Mantém as classes passadas via props
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};
