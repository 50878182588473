import { Routes, Route, Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';

import Home from '../../pages/Home/Home';
import Login from '../../pages/Login/Login';
import Register from '../../pages/Register/Register';
import MyProfile from '../../pages/Profile/MyProfile';
import ChangePassword from '../../pages/Profile/ChangePassword';
import Upload from '../../pages/Upload/Upload';
import ConfigStation from '../../pages/ConfigStation/ConfigStation';
import ConfigSystem from '../../pages/ConfigSystem/ConfigSystem';
import StationPage from '../../pages/StationPage/StationPage';
import DocType from '../../pages/DocType/DocType';
import Unsubscribe from '../../pages/Email/Unsubscribe/Unsubscribe';

import ConfirmationToken from '../../pages/Register/ConfirmationToken';
import ForgotPassword from '../../pages/ForgotPassword/ForgotPassword';
import RecoveryPassword from '../../pages/ForgotPassword/RecoveryPassword';
import ConfigUser from '../../pages/Admin/ConfigUser';
import Licensing from '../../pages/Licensing/Licensing';
import Project from '../../pages/Project/Project';
import Dashboard from '../../pages/Dashboard/Dashboard';
import Boleto from '../../pages/Boleto/Boleto';
import Notification from '../../pages/Notification/Notification';
import Email from '../../pages/Email/Email';

import { IRoute } from '../../Interfaces/IRoute';
import NotAuthorized from '../../pages/NotAuthorized/NotAuthorized';
import ReportDocuments from '../../pages/Document/ReportDocuments';
import TrialExpiredDialog from '../Login/TrialExpiredDialog';
import CNPJConfig from '../admin/CNPJConfig';
import ConfigBoleto from '../../pages/ConfigBoleto/ConfigBoleto';

//import { useEffect, useState } from "react";

const RoutesWrapper = () => {
  const { auth, loading, userData } = useAuth();

  //const [isModalOpen, setIsModalOpen] = useState(false);

  if (loading) {
    return <p>Carregando...</p>;
  }

  const ProtectedRoute = ({ allowedRoles }: IRoute) => {
    if (!auth || !userData) {
      return <Navigate to="/login" replace />;
    }

    if (allowedRoles && !allowedRoles.includes(userData.role)) {
      return <Navigate to="/not-authorized" replace />;
    }

    return <Outlet />;
  };

  const UnprotectedRoute = () => {
    if (!auth) {
      return <Outlet />;
    }

    return <Navigate to="/" replace />;
  };

  return (
    <>
      {/* Renderiza o diálogo de trial expirado se o período de trial tiver acabado */}
      {/*userData && <TrialExpiredDialog isOpen={isModalOpen} setIsOpen={setIsModalOpen} />*/}
      {userData && <TrialExpiredDialog />}
      <Routes>
        <Route path="/not-authorized" element={<NotAuthorized />} />

        <Route
          element={
            <ProtectedRoute
              allowedRoles={['superadmin', 'admin', 'regular', 'visualizador']}
            />
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<MyProfile />} />
          <Route path="/password" element={<ChangePassword />} />
          <Route path="/configsystem" element={<ConfigSystem />} />
          <Route path="/configstation" element={<ConfigStation />} />
          <Route path="/stationpage/:id" element={<StationPage />} />
          <Route path="/dashboard" element={<Dashboard report="painel" />} />
          <Route path="/reportdocuments" element={<ReportDocuments />} />
          <Route
            path="/dashboarddocuments"
            element={<Dashboard report="reportdocuments" />}
          />
        </Route>

        <Route
          element={
            <ProtectedRoute allowedRoles={['superadmin', 'admin', 'regular']} />
          }
        >
          <Route path="/upload" element={<Upload />} />
          <Route path="/boletos" element={<Boleto />} />
          <Route path="/doctype" element={<DocType />} />
          <Route path="/configboleto" element={<ConfigBoleto />} />
          <Route
            path="/dashboardboletos"
            element={<Dashboard report="boleto" />}
          />
        </Route>

        <Route
          element={<ProtectedRoute allowedRoles={['superadmin', 'admin']} />}
        >
          <Route path="/configusers" element={<ConfigUser />} />
          <Route path="/configcnpj" element={<CNPJConfig />} />
          <Route path="/licensing" element={<Licensing />} />
          <Route path="/project" element={<Project />} />
          <Route path="/configusers/edit/id" element={<ConfigUser />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/email" element={<Email />} />
        </Route>

        <Route element={<UnprotectedRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route
            path="/register/confirm/:tokenConfirm"
            element={<ConfirmationToken />}
          />
          <Route
            path="/register/confirm/:tokenConfirm"
            element={<ConfirmationToken />}
          />
          <Route
            path="/forgot/confirm/:tokenRecoveryConfirm"
            element={<RecoveryPassword />}
          />
          <Route path="/unsubscribe/:tokenUser" element={<Unsubscribe />} />
          <Route
            path="/register"
            element={
              <Register
                userData={null}
                setUpdateProfile={null}
                setRegisterChildUser={null}
                isFinishChild={false}
              />
            }
          />
          <Route
            path="/register/finishchild/:tokenConfirm"
            element={
              <Register
                userData={null}
                setUpdateProfile={null}
                setRegisterChildUser={null}
                isFinishChild={true}
              />
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default RoutesWrapper;
