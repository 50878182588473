// src/components/Chat/Chat.tsx

import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useAuth } from '../../hooks/useAuth';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';

import {
  chatSelector,
  fetchMessages,
  likeMessage,
  resetChat,
  sendMessage,
  addMessage,
  updateLikes,
} from '../../slices/ChatSlice';
import { IMessage, IChatStates } from '../../Interfaces/IChat';
import socket from '../../config/socket';
import {
  MdThumbUpAlt,
  MdDownload,
  MdClose,
  MdAttachFile,
} from 'react-icons/md';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// Configuração do worker do pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface ChatProps {
  chatId: string;
}

const Chat: React.FC<ChatProps> = ({ chatId }) => {
  const auth = useAuth();
  const userId = auth.userData?.id;
  const userName = auth.userData?.name;

  const dispatch = useAppDispatch();
  const { messages, chatLoading, chatError, chatMessage } = useAppSelector(
    chatSelector,
  ) as IChatStates;
  const [newMessage, setNewMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chatId || !userId) return;

    // Buscar mensagens iniciais
    dispatch(fetchMessages(chatId));

    // Conectar ao Socket.IO e ouvir eventos
    socket.emit('joinChat', chatId);

    socket.on('newMessage', (message: IMessage) => {
      dispatch(addMessage(message));
    });

    socket.on('updateLikes', (updatedMessage: IMessage) => {
      dispatch(updateLikes(updatedMessage));
    });

    return () => {
      socket.emit('leaveChat', chatId);
      socket.off('newMessage');
      socket.off('updateLikes');
      dispatch(resetChat());
    };
  }, [chatId, dispatch, userId, userName]);

  useEffect(() => {
    // Scroll automático para a última mensagem
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = async () => {
    if ((!newMessage.trim() && !selectedFile) || !auth) return;

    const messageData: Partial<IMessage> = {
      text: newMessage,
      userId: userId,
      userName: userName,
    };

    try {
      setUploading(true);
      await dispatch(
        sendMessage({ chatId, messageData, file: selectedFile || undefined }),
      ).unwrap();
      setNewMessage('');
      setSelectedFile(null);
      setUploading(false);
      // A mensagem será adicionada via Socket.IO
    } catch (error: any) {
      console.error('Erro ao enviar mensagem:', error);
      setUploading(false);
      // Opcional: Mostrar uma notificação de erro para o usuário
    }
  };

  const handleLike = async (messageId: string) => {
    if (!auth) return;

    try {
      // Dispatch do thunk likeMessage
      await dispatch(likeMessage({ chatId, messageId })).unwrap();
      // A atualização será recebida via Socket.IO
    } catch (error: any) {
      console.error('Erro ao dar like na mensagem:', error);
      // Opcional: Mostrar uma notificação de erro para o usuário
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  // Função para determinar o tipo de arquivo
  const getFileType = (
    fileUrl: string,
  ): 'image' | 'document' | 'general' | 'unknown' => {
    try {
      const url = new URL(fileUrl);
      const pathname = url.pathname;
      const extension = pathname.split('.').pop()?.toLowerCase();
      if (!extension) return 'unknown';

      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
      const documentExtensions = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
      ];

      if (imageExtensions.includes(extension)) return 'image';
      if (documentExtensions.includes(extension)) return 'document';
      return 'general';
    } catch (error) {
      console.error('Invalid URL:', error);
      return 'unknown';
    }
  };

  // Função para renderizar a pré-visualização do documento
  const renderDocumentPreview = (fileUrl: string) => {
    return (
      <div className="border border-gray-200 rounded p-2">
        <Document
          file={fileUrl}
          onLoadError={(error) => console.error('Erro ao carregar PDF:', error)}
          className="pdf-document"
        >
          <Page pageNumber={1} width={200} />
        </Document>
      </div>
    );
  };

  // Função para construir URL pública diretamente
  const getPublicUrl = (filePath: string) => {
    return `https://storage.googleapis.com/agiliza_entities_chats/${filePath}`;
  };

  return (
    <>
      <button onClick={() => setIsOpen(true)} className="customButton">
        Abrir Chat
      </button>
      {isOpen &&
        createPortal(
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-lg h-4/5 flex flex-col p-4 relative">
              {/* Botão de Fechar */}
              <button
                onClick={() => setIsOpen(false)}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                aria-label="Fechar Chat"
              >
                <MdClose size={24} />
              </button>

              {/* Conteúdo do Modal */}
              <h2 className="text-xl font-semibold mb-4">Chat</h2>
              <div className="flex-grow overflow-y-auto mb-4 border border-gray-300 rounded p-2">
                {chatLoading && <p>Carregando mensagens...</p>}
                {chatError && <p className="text-red-500">{chatMessage}</p>}
                <ul>
                  {messages &&
                    messages.map((msg) => {
                      const fileType = msg.fileUrl
                        ? getFileType(msg.fileUrl)
                        : null;
                      return (
                        <li key={msg._id} className="flex items-start mb-4">
                          <div className="flex-shrink-0 mr-3">
                            <div className="bg-blue-500 text-white rounded-full h-10 w-10 flex items-center justify-center">
                              {msg.userName.charAt(0).toUpperCase()}
                            </div>
                          </div>
                          <div className="flex-1">
                            <div className="flex items-center">
                              <span className="font-semibold mr-2">
                                {msg.userName}
                              </span>
                              <span className="text-gray-500 text-sm">
                                {new Date(msg.createdAt).toLocaleString()}
                              </span>
                            </div>
                            <p className="mt-1">{msg.text}</p>
                            {msg.fileUrl && (
                              <div className="mt-2">
                                {fileType === 'image' && (
                                  <div className="relative inline-block">
                                    <img
                                      src={msg.fileUrl}
                                      alt="Anexo"
                                      className="max-w-xs rounded"
                                    />
                                    <a
                                      href={msg.fileUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="absolute top-0 right-0 bg-white bg-opacity-70 hover:bg-opacity-100 rounded-full p-1"
                                    >
                                      <MdDownload size={16} />
                                    </a>
                                  </div>
                                )}

                                {fileType === 'document' && (
                                  <div>
                                    {msg.fileUrl.endsWith('.pdf') ? (
                                      renderDocumentPreview(msg.fileUrl)
                                    ) : (
                                      <p>
                                        <a
                                          href={msg.fileUrl}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-blue-500 hover:underline"
                                        >
                                          {msg.fileUrl.split('/').pop()}
                                        </a>
                                      </p>
                                    )}
                                    <a
                                      href={msg.fileUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-500 hover:underline flex items-center mt-1"
                                    >
                                      <MdDownload className="mr-1" />
                                      Baixar
                                    </a>
                                  </div>
                                )}

                                {fileType === 'general' && (
                                  <div className="flex items-center">
                                    <a
                                      href={msg.fileUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-500 hover:underline mr-2"
                                    >
                                      {msg.fileUrl.split('/').pop()}
                                    </a>
                                    <a
                                      href={msg.fileUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-500 hover:underline"
                                    >
                                      <MdDownload size={16} />
                                    </a>
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="flex items-center mt-2">
                              <button
                                onClick={() => handleLike(msg._id)}
                                className="text-blue-500 hover:text-blue-600 focus:outline-none mr-1"
                              >
                                <MdThumbUpAlt size={20} />
                              </button>
                              <span>{msg.likes}</span>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
                <div ref={messagesEndRef} />
              </div>
              <div className="flex items-center">
                <textarea
                  className="customInputText w-full h-8"
                  placeholder="Digite sua mensagem..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                  rows={1}
                />
                <label htmlFor="attach-file" className="cursor-pointer">
                  <MdAttachFile
                    size={24}
                    className="text-top-digital hover:text-top-digital-hover"
                  />
                  <input
                    accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    className="hidden"
                    id="attach-file"
                    type="file"
                    onChange={handleFileChange}
                  />
                </label>
                <button
                  onClick={handleSendMessage}
                  className="customButton"
                  disabled={uploading || chatLoading}
                >
                  Enviar
                </button>
              </div>
              {selectedFile && (
                <div className="flex items-center mt-2">
                  <span className="text-sm text-gray-700">
                    {selectedFile.name}
                  </span>
                  <button
                    onClick={() => setSelectedFile(null)}
                    className="ml-2 text-red-500 hover:text-red-600 focus:outline-none"
                  >
                    Remover
                  </button>
                </div>
              )}
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

export default Chat;
